<template>
  <div class="card p-shadow-6">
    <h1>Intervenções Utilizadores Inativos</h1>

    <Dialog
      id="'dialog-hours'"
      :visible.sync="showTaskForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Ver Tarefa'"
    >
      <TaskForm
        :task="userTask"
        :interventionTypes="[]"
        v-on:cancelTaskForm="taskFormCanceled"
      />
    </Dialog>

    <Dialog
      id="'calendarGoTo'"
      :visible.sync="calendarGoTo"
      :style="{ width: $isMobile() ? '100vw' : '35vw' }"
      :modal="true"
      :header="'Ir para dia'"
    >
      <div class="p-col-12 p-text-center">
        <v-calendar @dayclick="goDate" />
      </div>
    </Dialog>

    <div class="p-mb-2">
      Visualizar:
      <Dropdown
        name="myUsersList"
        class="form-control"
        v-model="userId"
        :inputId="'myUsersList'"
        :options="myUsers"
        :optionLabel="'username'"
        :optionValue="'id'"
        :dataKey="'id'"
        :filter="true"
        @input="userChanged"
      />
    </div>
    <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div
          v-if="arg.event._def.extendedProps.isHoliday"
          class="position-fixed"
        >
          <div>
            <b>Feriado:</b><br />
            {{ arg.event.title }}
          </div>
        </div>
        <div
          v-else-if="arg.event._def.extendedProps.inVacation"
          class="position-fixed"
        >
          <div style="color: #000000">
            <b>Férias</b>
            <br />
            {{ arg.event.title }}
          </div>
        </div>
        <div
          v-else-if="arg.event._def.extendedProps.missed"
          class="position-fixed"
        >
          <div><b>Falta:</b> {{ arg.event.title }}</div>
        </div>
        <div v-else>
          <div class="p-text-right">
            <i
              v-if="arg.event._def.extendedProps.taskInfo.isBooking"
              class="pi pi-info-circle p-mr-1"
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>Indisponível</b>' +
                '</div>'
              "
            ></i>
            <i
              v-else
              class="pi pi-info-circle p-mr-1"
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>Pat: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_number +
                '<br />' +
                '<b>Título: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_title +
                '</div>' +
                '<br />' +
                '<b>Trabalho efectuado: </b>' +
                arg.event._def.extendedProps.taskInfo.resume +
                '</div>'
              "
            ></i>
            <i
              class="pi pi-eye p-mr-1"
              v-on:click="updateFromForm(arg.event.id)"
              v-tooltip="'Ver tarefa'"
            ></i>
          </div>
          <div class="text-ellipsis">{{ arg.timeText }}</div>
          <div v-if="arg.event._def.extendedProps.taskInfo.isBooking == true">
            <b>Indisponível</b>
          </div>
          <div v-else>
            <div class="text-ellipsis">
              <b>Pat:</b> {{ arg.event._def.extendedProps.taskInfo.pat_number }}
            </div>
            <div
              v-if="arg.event._def.extendedProps.taskInfo.pat_title != ''"
              class="text-ellipsis"
            >
              <b>Título: </b>
              {{ arg.event._def.extendedProps.taskInfo.pat_title }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:dayHeaderContent="arg">
        <span class="p-md-10 p-text-left">
          {{ arg.text }}
        </span>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import taskForm from "./TaskForm";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ptLocale from "@fullcalendar/core/locales/pt";
import { getOnlyDate } from "../helpers/helpers";
import Task from "../models/task";
import taskService from "../services/task.service";
import employeeService from "../services/employee.service";

import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "hoursInactiveUsers",
  components: {
    TaskForm: taskForm,
    Loading: loading,
  },
  data() {
    return {
      userTask: null,
      showTaskForm: false,
      userId: null,
      calendarApi: null,
      tasksInCalendar: [],
      myUsers: [],
      calendarOptions: null,
      calendarGoTo: false,
      isLoading: true,
    };
  },
  beforeCreate() {
    employeeService.getSupportInactives().then((response) => {
      this.myUsers = response;
      return (this.isLoading = false);
    });
  },
  beforeMount() {
    this.generateCalendar();
  },
  mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
  },
  methods: {
    generateCalendar() {
      const calendarGoTo = () => (this.calendarGoTo = true);
      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
        ],
        locale: ptLocale,
        allDaySlot: false,
        selectable: false,
        firstDay: 1,
        height: 700,
        scrollTime: "09:00",
        slotDuration: "00:15",
        slotLabelInterval: "01:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        initialView: this.$isMobile() ? "timeGrid" : "timeGridWeek",
        displayEventTime: true,
        displayEventEnd: true,
        editable: false,
        customButtons: {
          showCalendar: {
            text: "Ir para",
            click: function () {
              calendarGoTo();
            },
          },
        },
        headerToolbar: {
          left: "prev,showCalendar,next",
          center: "title",
          right: "",
        },
      };
      const getTasks = (dateStart, dateEnd) =>
        this.getUserTasks(dateStart, dateEnd);

      this.calendarOptions.events = function (info, successCallback) {
        getTasks(getOnlyDate(info.start), getOnlyDate(info.end)).then(
          (tasks) => {
            successCallback(tasks);
          }
        );
      };

      const updateTaskForm = (taskId) => this.updateFromForm(taskId);
      this.calendarOptions.eventDidMount = function (eventInfo) {
        eventInfo.el.id = eventInfo.event.id;
        eventInfo.el.ondblclick = function () {
          updateTaskForm(eventInfo.event.id);
        };
      };
    },
    getUserTasks(dateStart, dateEnd) {
      if (!this.userId) {
        return new Promise(function (resolve) {
          resolve([]);
        }).then((r) => r);
      }
      this.isLoading = true;
      this.removeAllTasks();
      return taskService
        .getTasksAndBookings(this.userId, dateStart, dateEnd)
        .then((days) => {
          this.tasksInCalendar = days;
          let userTasks = [];
          days.forEach((day) => {
            if (day.isHoliday || day.inVacation || day.missed) {
              let backgroundTask = {
                start: `${day.day} 00:00`,
                end: `${day.day} 23:59`,
                isHoliday: false,
                inVacation: false,
                missed: false,
                display: "background",
              };
              if (day.isHoliday) {
                backgroundTask.title = day.isHoliday;
                backgroundTask.isHoliday = true;
              } else if (day.inVacation) {
                backgroundTask.title = "";
                if (!day.inVacation.approved) {
                  backgroundTask.title = "(Não Aprovada)";
                }
                backgroundTask.inVacation = true;
                backgroundTask.color = "#33ff00";
              } else if (day.missed) {
                backgroundTask.title = day.missed;
                backgroundTask.missed = true;
                backgroundTask.color = "#ff0000";
              }
              userTasks.push(backgroundTask);
            }
            day.tasks
              .filter(
                (task) =>
                  !(
                    task.isBooking != undefined &&
                    task.isBooking == true &&
                    task.type == 1
                  )
              )
              .forEach((task) => {
                userTasks.push({
                  id:
                    task.isBooking != undefined && task.isBooking == true
                      ? `booking-${task.id}`
                      : task.id,
                  title:
                    task.isBooking != undefined && task.isBooking == true
                      ? `Indisponível`
                      : `${task.pat_number} - ${task.pat_title}`,
                  start:
                    task.isBooking != undefined && task.isBooking == true
                      ? `${task.date} ${task.start}`
                      : task.start,
                  end:
                    task.isBooking != undefined && task.isBooking == true
                      ? `${task.date} ${task.end}`
                      : task.end,
                  color: task.color,
                  editable: false,
                  taskInfo: task,
                });
              });
          });
          this.isLoading = false;
          return userTasks;
        });
    },
    taskFormCanceled() {
      this.userTasks = null;
      this.showTaskForm = false;
    },
    updateFromForm(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;
      this.userTask = new Task();

      if (taskInfo.isBooking != undefined && taskInfo.isBooking == true) {
        this.userTask.isBooking = true;
        this.userTask.bookingId = taskInfo.id;
        this.userTask.day = taskInfo.date;
        this.userTask.start = new Date(`${taskInfo.date} ${taskInfo.start}`);
        this.userTask.end = new Date(`${taskInfo.date} ${taskInfo.end}`);
        this.userTask.type = taskInfo.type;
        this.userTask.taskType = taskInfo.type;
      } else {
        taskInfo.start = new Date(taskInfo.start);
        taskInfo.end = new Date(taskInfo.end);
        this.userTask.start = taskInfo.start;
        this.userTask.end = taskInfo.end;
        this.userTask.day = getOnlyDate(taskInfo.start);
        this.userTask.isBooking = false;
        this.userTask.localization = taskInfo.localization;
        this.userTask.type = taskInfo.typeId = !undefined ? taskInfo.typeId : 0;
        this.userTask.taskType = 0;
      }

      this.userTask.action = "view";
      this.userTask.resume = taskInfo.resume;
      this.userTask.description = taskInfo.description;
      this.userTask.patNumber = taskInfo.pat_number;
      this.userTask.taskId = taskId;
      this.userTask.color = taskInfo.color;
      this.userTask.task_all_info = taskInfo;
      this.showTaskForm = true;
    },
    userChanged(select) {
      this.userId = select;
      return taskService.lastTaskDate(this.userId).then((response) => {
        return this.goDate({ id: response.lastTaskDate });
      });
    },
    goDate(date) {
      this.calendarApi.gotoDate(date.id);
      this.calendarGoTo = false;
    },
    removeAllTasks() {
      if (!this.calendarApi) {
        return;
      }
      let events = this.calendarApi.getEvents();
      events.forEach((event) => {
        event.remove();
      });
    },
  },
};
</script>

<style>
.fc-event-main {
  background: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: 0px !important;
  overflow: hidden;
}

.day-header-status {
  cursor: pointer;
}

.lockNow {
  color: red;
}

.position-fixed {
  position: fixed;
}

.fc-scrollgrid-shrink,
.fc-scrollgrid-shrink + td {
  border-top-width: 3px !important;
}

/* .fc .fc-timegrid-col.fc-day-today {
   background: rgb(0, 0, 255) !important;
}  */

.fc .fc-view-harness td {
  background: rgba(0, 0, 0, 0);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-tooltip {
  z-index: 99;
}
</style>
